import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../providers/global.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HTTP, private gs: GlobalService
  ) {
    this.gs.pegaDadosUser();
  }

  indicadores(): Observable<any> {
    this.http.setDataSerializer('json');

    let dti1, dta1;
    // try {
    //   dti1 = $filter('date')(document.getElementById('dtinicial').value, 'yyyyMMdd');
    //   dta1 = $filter('date')(document.getElementById('dtatual').value, 'yyyyMMdd');;
    // } catch (e) {
    // dti1 = ((new Date()).getFullYear() + '-' + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + '-01');
    // dta1 = ((new Date()).getFullYear() + '-' + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + '-' + ("0" + ((new Date()).getUTCDate() + 1)).slice(-2));
    dti1 = ((new Date()).getFullYear() + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + '01');
    dta1 = ((new Date()).getFullYear() + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + ("0" + ((new Date()).getUTCDate() + 1)).slice(-2));
    dti1 = ((new Date()).getFullYear() + "01" + '01');
    dta1 = ((new Date()).getFullYear() + "12" + '31');
    //  }

    // console.log(dti1);
    // console.log(dta1)

    let url = `${environment.urlAPI}GetIndicatorsDashboard/${this.gs.dadosUser.Token}celular/${this.gs.dadosUser.UserID}/${dti1}/${dta1}/N/0`;
    console.log('AAAA');
    console.log(url);
    return from(this.http
      .get(url, null, {})
      .then((d) => {
        return d;
      })
      .catch((ex) => {
        return ex;
      }));
  }

  bancosCorretoras(): Observable<any> {
    this.http.setDataSerializer('json');

    let url = (`https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/GetDataGenericReports/'0000APIcelular/${this.gs.dadosUser.UserID}/8961`);

    return from(this.http
      .get(url, null, {})
      .then((d) => {
        return d;
      })
      .catch((ex) => {
        return ex;
      }));

  }

  mensagens(): Observable<any | null> {
    this.http.setDataSerializer('json');

    let url = (`https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/GetDataGenericReports/'0000APIcelularSQLSERVER/analista@nau.com/8950/[[C][@]FilterName[@]:[@]idsql[@],[@]FilterValue[@]:[@]0[@],[@]FilterCaption[@]:[@]Codigo[@][C1],[C][@]FilterName[@]:[@]SelectManual[@],[@]FilterValue[@]:[@]/select%20DATA,MENSAGEM,%20TITULO,%20/IMAGEM%20from%20sic2401%20where%20idlicenca=101[@],[@]FilterCaption[@]:[@]Select[@][C1]/,[C][@]FilterName[@]:[@]DataFinal[@],[@]FilterValue[@]:[@]20/200101[@],[@]FilterCaption[@]:[@]DataInicial[@][C1],[C][@]FilterName[@]:[@]DataInici/al[@],[@]FilterValue[@]:[@]20200101[@],[@]Filter/Caption[@]:[@]Datafinal[@][C1]]`);

    // return from(
    //   this.http.get(url, null, {})
    //     .then((d) => {
    //       return d;
    //     })
    //     .catch((ex) => {
    //       return ex;
    //     })
    // );


    // let sel = `select IDIND, GEP9903.DESCRICAO,(select RESPOSTA FROM gep9911 where 
    //   idvinc=${this.gs.dadosUser.coduser} and idindicador=gep9903.idind) as RESPOSTA,GRUPO,
    //   GEP9903.IMAGEM,GEP9903.VIDEO, GEP9902.IDOBJETIVO, TIPO_CAMPO, 0 AS VALOR FROM
    //   GEP9903,GEP9902 WHERE GEP9902.idobjetivo=GEP9903.idobjetivo 
    //   and GEP9902.IDOBJETIVO=${o} ORDER BY IDIND`;




    let sel = `select DATA,MENSAGEM, TITULO, IMAGEM from sic2401 where idlicenca=101`;


    const h = {
      'Content-Type': 'application/json',
      'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc5NDMzNzI1fQ.5p3DTHVHxH1uoRNw6DbXRTgVReN5maRtXlip3w9PZH8',
      'e-usuario': this.gs.dadosUser.UserID,
    };

    let q = { query: sel };

    url = 'https://apinodejs.gepbusiness.com/web/queryFB';

    return from(this.http
      .post(url, q, h)
      .then((d) => {
        return d;
      })
      .catch((ex) => {
        return ex;
      }));
  }

  falaAvatar(): Observable<any | null> {
    this.http.setDataSerializer('json');

    let url = (`https://acesso.naufinance.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/GetAI/${this.gs.dadosUser.UserID}/${this.gs.dadosUser.Idioma}/frase/1`);

    return from(
      this.http.get(url, null, {})
        .then((d) => {
          //console.log(d);
          return d;
        })
        .catch((ex) => {
          console.log(ex);
          return ex;
        })
    );
  }

  mandarTextoXX(texto: string): Observable<any | null> {
    const h = {
      //'Content-Type':'application/json;charset:utf-8'
    };
    this.http.setDataSerializer('json');
    //texto = encodeURI(texto);



    let url = (`https://acesso.naufinance.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/GetAI/${this.gs.dadosUser.UserID}/${this.gs.dadosUser.Idioma}/${texto}`);

    url = encodeURI(`https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/getai/${this.gs.dadosUser.UserID}/${this.gs.dadosUser.Idioma}/${texto}/N`);

    // let url=(`https://acesso.naufinance.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/GetAI/${this.gs.dadosUser.UserID}/pt/${texto}`);
    //url =  `https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/Getnamelist/${this.gs.dadosUser.UserID}/AI/BR|/${texto}`;
    // console.log(url);
    return from(
      this.http.get(url, null, h)
        .then((d) => {
          // console.log(d);
          return d;
        })
        .catch((ex) => {
          console.log(ex);
          return ex;
        })
    );
  }

  mandarTexto(texto: string): Observable<any | null> {
    const h = {
      //'Content-Type':'application/json;charset:utf-8',
      'e-usuario': this.gs.dadosUser.UserID,
    };
    this.http.setDataSerializer('json');

    let url = encodeURI(`https://apinodejs.gepbusiness.com/web/getai/${texto}`);
    //console.log(url);

    return from(
      this.http.get(url, null, h)
        .then((d) => {
          // console.log(d);
          if (d.status === 200) {
            return JSON.parse(d.data);
          }
          return d;
        })
        .catch((ex) => {
          console.log(ex);
          return ex;
        })
    );
  }

  listaGepshows() {
    const h = {
      //'Content-Type':'application/json;charset:utf-8'
    };
    this.http.setDataSerializer('json');

    let url;// = encodeURI(`https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/getai/${this.gs.dadosUser.UserID}/${this.gs.dadosUser.Idioma}/${texto}/N`);
    url = encodeURI(`https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/GetApresentationGepShow/0/${this.gs.dadosUser.UserID}`);

    return from(
      this.http.get(url, null, h)
        .then((d) => {
          // console.log(d);
          return d;
        })
        .catch((ex) => {
          console.log(ex);
          return ex;
        })
    );
  }

  pegaGepshow(id) {
    const h = {
      //'Content-Type':'application/json;charset:utf-8'
    };
    this.http.setDataSerializer('json');

    let url;// = encodeURI(`https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/getai/${this.gs.dadosUser.UserID}/${this.gs.dadosUser.Idioma}/${texto}/N`);
    url = encodeURI(`https://www.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/GetSlideGepShow/Token/${this.gs.dadosUser.UserID}/${id}`);

    return from(
      this.http.get(url, null, h)
        .then((d) => {
          // console.log(d);
          return d;
        })
        .catch((ex) => {
          console.log(ex);
          return ex;
        })
    );
  }

}
