import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Platform, ToastController } from '@ionic/angular';
import { GlobalService } from './providers/global.service';
import * as $ from 'jquery';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { TextToSpeechAdvanced } from '@ionic-native/text-to-speech-advanced/ngx';
import { DashboardService } from './services/dashboard.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {Router} from '@angular/router';

declare var window;
declare var sayText: any;
declare var AC_VHost_Embed: any;
declare var unloadScene: any;
declare var loadSceneByID: any;
declare var vh_talkEnded: any;
declare var dynamicResize: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  posicaoHelp = 1;

  constructor(
    private platform: Platform, public gs: GlobalService, private ds: DashboardService,
    private toastCtrl: ToastController, private spk: SpeechRecognition, private tts: TextToSpeechAdvanced,
    private iab: InAppBrowser, private router: Router
  ) {
    this.initializeApp();

    this.router.events.subscribe((val) => {
      let url = this.router.url.toLowerCase();
      if (url.indexOf('signin') > -1 || url.indexOf('alunos') > -1) {
        this.posicaoHelp = 1;
      } else {
        this.posicaoHelp = 0;
      }
    });
  }

  // Initialize app
  initializeApp() {


    // Wait until platform is ready
    this.platform.ready().then(async () => {

      this.iniciaAvatar();

      // If we're on a mobile platform (iOS / Android), not web
      if (Capacitor.getPlatform() !== 'web') {

        // Set StatusBar style (dark / light)
        await StatusBar.setStyle({ style: Style.Dark });
      }

      // ...
      // do some more config and setup if necessary
      // ...

      // Fake timeout since we do not load any data
      setTimeout(async () => {

        // Hide SplashScreen
        await SplashScreen.hide();
      }, 2000);
    });

    window.app = this;
  }

  //AVATAR
  iniciaAvatar() {
    //setTimeout(()=>{
    //loadScript('https://vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=6768093&js=1');
    AC_VHost_Embed(6768093, 150, 200, '', 1, 1, 2735195, 0, 1, 0, '18be7e498e6fa7898fa93261fab536d9', 0, '200|150|15|15|L|T|false|0|0|0|0|vhss.oddcast.com/ccs2/vhss/user/5f0/6768093/thumbs/show_2735195.jpg|Double%20click%20to%20play%20me|0|C|0|0|0|875');
    //},2000);
    // setTimeout(()=>{
    //   $('._html5Player').attr('style','width: 200px; overflow: hidden; height: 150px; position: fixed; bottom: 45px; right: 0px; left: 10px;');
    setTimeout(()=>{
      window.vh_talkEnded = () => { 
        this.escondeAvatar();
       };
    },3000);
  }

  fazAvatarFalar(texto: any) {
    let idioma = 6;
    let voz = 1;
    let engine = 3;
    if (this.gs.dadosUser.Idioma.toLowerCase().indexOf('en') > -1) {
      idioma = 1;
      voz = 3;
      engine = 3;
    } else if (this.gs.dadosUser.Idioma.toLowerCase().indexOf('es') > -1) {
      idioma = 2;
      voz = 1;
      engine = 3;
    } else if (this.gs.dadosUser.Idioma.toLowerCase().indexOf('pt') > -1) {
      idioma = 6;
      voz = 1;
      engine = 3
    }

    this.mostraAvatar();
    sayText(texto, voz, idioma, engine);
  }

  resizeAvatar(w, h) {
    dynamicResize(400, 200);
  }

  mostraAvatar() {
    //loadSceneByID();
    $('._html5Player').css({ visibility: 'visible' });
    $('._html5Player').attr('style', 'width: 200px; overflow: hidden; height: 200px; position: fixed; bottom: 25px; right: 0px; left: 15px;visibility:visible;');
  }

  escondeAvatar() {
    //unloadScene();
    $('._html5Player').hide();
  }
  //AVATAR

  speak() {
    // this.tts.speak({text:'testando fala', locale: 'en-US', identifier:''}).then(()=>{

    // });

    // setTimeout(()=>{
    //   this.tts.speak({text:'testando fala', locale: 'pt-BR', identifier:''}).then(()=>{});
    // },3000);


    //if (this.platform.is('ios') || this.platform.is('android') || 1==1) {
    this.spk.hasPermission().then((perm) => {
      if (!perm) {
        this.spk.requestPermission()
          .then(
            () => {
              console.log('Granted');
              this.showToast('Permissão concedida');
              this.escutar();
            },
            () => {
              console.log('Denied')
              this.showToast('Permissão negada');
            }
          )
      } else {
        this.showToast('Já tem permissão. Começando a escutar');
        this.escutar();
      }
    }).catch((er) => {
      this.showToast(JSON.stringify(er));
    });
    // }else{
    //   this.escutar();
    // }
  }

  pararEscuta() {
    setTimeout(() => {
      $('.microfone').removeClass('mic-piscar');
      this.spk.stopListening().then();
    }, 3500);
  }

  iniciado = false;
  escutar() {
    let idioma = 'pt-BR';

    if (this.gs.dadosUser.Idioma.toLowerCase().indexOf('en') > -1) {
      idioma = 'en-US';
    } else if (this.gs.dadosUser.Idioma.toLowerCase().indexOf('es') > -1) {
      idioma = 'es-ES';
    } else if (this.gs.dadosUser.Idioma.toLowerCase().indexOf('pt') > -1) {
      idioma = 'pt-BR';
    }

    if (this.platform.is('ios')) {
      if (!this.iniciado) {
        this.iniciado = true;
      } else {
        this.iniciado = false;
        this.spk.stopListening().then();
        return;
      }
      //idioma = idioma.replace('-','_');
      $('.microfone').addClass('mic-piscar');
      setTimeout(() => {
        $('.microfone').removeClass('mic-piscar');
        this.spk.stopListening().then();
        this.iniciado = false;
      }, 10000);




      this.spk.startListening({ language: idioma, matches: 1 }).subscribe((r) => {
        this.pararEscuta();

        //window.app.textoParaFala(r[0]); //aqui pega o texto do que foi falado no microfone
        //this.showToast('texto falado: ' + encodeURI(r[0]));
        //console.log(r);
        //this.showToast('tamanho do array: ' + JSON.stringify(r));

        this.ds.mandarTexto(encodeURI(r[0])).subscribe((ret) => { //aqui manda para a url do servidor

          this.showToast('o que deve falar: ' + ret.data);
          let v;
          try {
            v = JSON.parse(ret.data).DsMensagem;
          } catch (ex) { }
          this.falaAvatar(v); //aqui num ta falando nada, não foi feito
        });
      }, (er) => {

        $('.microfone').removeClass('mic-piscar');
        this.spk.stopListening().then();
        //this.showToast(JSON.stringify(er));
      });

      // this.spk.startListening({ language: idioma, matches: 1 }).subscribe((r) => {
      //   //window.app.textoParaFala(r[0]); //aqui pega o texto do que foi falado no microfone
      //  this.dashSvc.mandarTexto(r[0]).subscribe((ret) => { //aqui manda para a url do servidor
      //    window.app.falaAvatar(ret.data); //aqui num ta falando nada, não foi feito
      //  });
      //},(er)=>{alert(JSON.parse(er))});




    } else if (this.platform.is('android')) {
      this.spk.startListening({ language: idioma, matches: 1 }).subscribe((r) => {
        //window.app.textoParaFala(r[0]); //Aqui pega o texto do q foi falado no microfone
        this.ds.mandarTexto(r[0]).subscribe((ret) => {

          this.falaAvatar(JSON.parse(ret));
        }, (er) => {
          this.showToast('Erro ao enviar para o servidor');
          this.showToast(er);
        }, () => {

        });
      });
    } else {
      this.ds.mandarTexto('A2MIA').subscribe((ret) => {
        this.falaAvatar(ret.data);
      });
    }
  }

  showToast(texto: any) {
    let t = this.toastCtrl
      .create({
        message: texto,
        duration: 3000,
        position: 'top'
      })
      .then((d) => {
        d.present();
      });
  }



  ultimaFrase = 'xxx';
  falaAvatar(texto?) {
    console.log(texto);
    let t;
    try {
      t = JSON.parse(texto.data);
    } catch (ex) {
      t = texto;
    }

    if (!t || t === '') { return; }

    texto = t.DsMensagem;
    try {
      if (!texto) {
        console.log(texto);
        this.ultimaFrase = texto.trim();
        this.textoParaFala(texto);
        // this.dashSvc.falaAvatar().subscribe((v) => {
        //   let r;
        //   try {
        //     r = JSON.parse(v.data);
        //   } catch (ex) { }
        //   if (r && r.result) {
        //     if (r.result.length > 0) {
        //       if (r.result[0].trim() !== '' && r.result[0].trim() !== this.ultimaFrase) {
        //         this.ultimaFrase = r.result[0].trim();
        //         this.textoParaFala(r.result[0]);
        //       }
        //     }
        //   }
        // },
        //   null,
        //   () => {
        //   });
      } else {
        this.textoParaFala(texto);
      }

    } catch (ex) {
      this.showToast('erro');
      this.showToast(JSON.stringify(ex));
    }
  }

  opt: any;//TTSOptions;
  textoParaFala(texto: string) {
    this.fazAvatarFalar(texto);
    return;
    let idioma = 'pt-BR';

    if (this.gs.dadosUser.Idioma.indexOf('en') > -1) {
      idioma = 'en-US';
    } else if (this.gs.dadosUser.Idioma.indexOf('es') > -1) {
      idioma = 'es-ES';
    } else if (this.gs.dadosUser.Idioma.indexOf('pt') > -1) {
      idioma = 'pt-BR';
    }
    if (this.platform.is('ios')) {
      this.opt = { volume: 1.0 };
      this.tts.speak({ text: texto, locale: 'pt-BR', identifier: '', rate: 0.5, pitch: 0.9 })
        .then(() => {
          this.showToast('sucesso ios');
        })
        .catch((er) => {
          this.showToast('erro ios');
        });
    } else {
      this.tts.speak({ text: texto, locale: idioma, identifier: '', rate: 1.3, pitch: 0.8 })
        .then(() => {
          this.showToast('sucesso');
        })
        .catch((er) => {
          this.showToast('erro');
          this.showToast(JSON.stringify(er));
        });
    }
  }

  abrirHelp() {
    // const browser = this.iab.create('https://wa.me/15107397849', '_system');
    const browser = this.iab.create('https://wa.me/14076690098', '_system');
    // +55 11 95325-9186
  }

}
